<template>
  <div>
    <div
      id="appointment-listing"
      class="ml-8"
      v-if="$store.state.auth.user.roles[0].name"
    >
      <h1 class="appointments text-justify text-xl mt-12 text-appointments">
        Profile Reports
      </h1>
      <div class="flex justify-between sm:flex-row flex-col">
        <button
          class="w-56 bg-create download-btn rounded"
          @click="printDocument"
        >
          Print Document
        </button>

        <div class="lg:ml-40 lg:flex mt-2 sm:mr-40 sm:mt-0">
          <div class="select">
            <select
              class="
                month-select
                border-black border
                mb-4
                lg:mb-0
                p-1.5
                w-36
                rounded-sm
                border-gray-300
              "
              @change="onSelectedMonthChange($event)"
            >
              <option value="ALL">ALL</option>
              <option value="1">January</option>
              <option value="2">February</option>
              <option value="3">March</option>
              <option value="4">April</option>
              <option value="5">May</option>
              <option value="6">June</option>
              <option value="7">July</option>
              <option value="8">August</option>
              <option value="9">September</option>
              <option value="10">October</option>
              <option value="11">November</option>
              <option value="12">December</option>
            </select>
          </div>
          <div class="select">
            <select
              class="
                year-select
                border-black border
                p-1.5
                mb-4
                lg:mb-0
                w-36
                lg:ml-8
                border-gray-300
              "
              @change="onSelectedYearChange($event)"
            >
              <option
                v-for="year in appointmentYears"
                :key="year.year"
                :value="year.year"
              >
                {{ year.year }}
              </option>
            </select>
          </div>

          <div class="">
        <Search
          label="Search by name"
          class="w-52 ml-4"
          @input="searchAppointment"
        />
      </div>
        </div>
      </div>
    </div>
    <div class="document" id="documentToDownload" v-if="profiles.length > 0">
      <h1 class="members">Profiles</h1>
      <div class="members-underline"></div>
      <div>
        <ReportProfile
          v-for="(profile, i) in profiles"
          :key="i"
          :id="searching?profile.appointee.id : profile.id"
          :names="searching? profile.appointee.names : profile.names"
          :email="searching? profile.appointee.email : profile.email"
          :address="searching? profile.appointee.location : profile.location"
          :imageUrl="searching? profile.appointee.imageUrl : profile.imageUrl"
          :phone="searching ? profile.appointee.mobile : profile.mobile"
          :institution="searching? profile.appointee.title : profile.title"
          :nationalId="searching? profile.appointee.nationalId: profile.nationalId"
          :age="searching? profile.appointee.age : profile.age"
          :cvPath="searching? profile.appointee.cvPath : profile.cvPath"
        />
      </div>
    </div>
    <ElementsNotFound
      v-show="!elementsAvailable"
      :isLoading="loading"
      type="Reports"
    />
    
  </div>
</template>

<script>
import Api from "@/services/Apis";
import ReportProfile from "../../components/Listings/ReportProfile.vue";
import ElementsNotFound from "/src/components/ElementsNotFound.vue";
import Search from "/src/components/searchInput.vue";

export default {
  name: "AppointmentReport",
  components: {
    ReportProfile,
    ElementsNotFound,
    Search
  },
  data() {
    return {
      appointmentYears: [],
      currentSelectedYear: "ALL",
      currentSelectedMonth: "ALL",
      elementsAvailable: false,
      profiles: [],
      loading: true,
      searching: false
    };
  },
  methods: {
    async getAppointmentYears() {
      await Api.get("statistics/appointments/list-years")
        .then((resp) => {
          this.appointmentYears = [{year: "ALL"},...resp.data];
        })
        .catch((error) => {
          console.log(error);
        });
    },
    onSelectedYearChange(e) {
      this.currentSelectedYear = e.target.value;
      this.getAppointees(this.currentSelectedMonth, this.currentSelectedYear);
    },
    onSelectedMonthChange(e) {
      this.currentSelectedMonth = e.target.value;
      this.getAppointees(this.currentSelectedMonth, this.currentSelectedYear);
    },
    printDocument() {
      var divContents = document.getElementById("documentToDownload").innerHTML;
      let stylesHtml = "";
      for (const node of [
        ...document.querySelectorAll('link[rel="stylesheet"], style'),
      ]) {
        stylesHtml += node.outerHTML;
      }
      var myWindow = window.open("", "", "height=800, width=800");
      myWindow.document.write("<html>");
      myWindow.document.write("<head>");
      myWindow.document.write("<title> Cok Members");
      myWindow.document.write("</title>");
      myWindow.document.write(stylesHtml);
      myWindow.document.write("<head/>");
      myWindow.document.write("<body >");
      myWindow.document.write(divContents);
      myWindow.document.write("</body></html>");
      myWindow.document.close();
      myWindow.print();
    },

    async getAppointees(month, year) {
      await Api.get(`appointee/report${year === "ALL" ? "" : month === "ALL" ? `/${year}` : `/${month}/${year}`}`)
        .then((response) => {
          this.profiles = response.data.data;
          console.log(this.profiles)
          this.loading = false;
          this.elementsAvailable = true;
        })
        .catch((error) => {
          console.log(error);
        });
    },

    async searchAppointment(searchKey) {
      if (searchKey === "") {
          this.getAppointees(this.currentSelectedMonth, this.currentSelectedYear);
          this.searching = false;
      } else {
        await Api.get("appointment/search/" + searchKey)
          .then((resp) => {
            this.profiles = resp.data;
            if (!this.profiles.length) {
              this.elementsAvailable = false;
            } else {
              this.elementsAvailable = true;
              this.searching = true
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    }
  },
  created() {
    this.getAppointmentYears();
    this.getAppointees(this.currentSelectedMonth, this.currentSelectedYear);
  },
};
</script>

<style scoped>
#documentToDownload {
  width: 82%;
  min-width: 65%;
  height: auto;
  min-height: 100%;
  background: white;
  margin: 2rem 0 0 2rem;
  padding: 1rem 1rem;
  color: #2c3e50;
}
.members {
  text-align: center;
  font-weight: 600;
}
.download-btn {
  color: white;
  margin-top: 1rem;
  height: 2.5rem;
}
.members-underline {
  height: 0.1rem;
  background-color: black;
  border: 1px solid black;
}
@media only screen and (max-width: 760px) {
  #documentToDownload {
    width: 80%;
    min-width: 100%;
    padding: 0;
  }
}
</style>
